import api from '@/utils/api'

export default {
    getFaqs(page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`faqs` + (page ? range : ''))
    },

    getFaq(id) {
        return api.get(`faqs/${id}`)
    },

    createFaq(data) {
        return api.post('faqs', data)
    },

    updateFaq(id, data) {
        return api.put(`faqs/${id}`, data)
    },

    deleteFaq(id) {
        return api.delete(`faqs/${id}`)
    }
}
